/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AppointmentsListContainer from '../components/AppointmentsListContainer';
import { appointmentBaseStore } from '../../shared/singletons';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { UserContext } from '../../auth/contexts/userContext';
import { today } from '../../shared/utils/date.utils';

export type AllAppointmentsPageProps = Record<string, unknown>;

const AllAppointmentsPage: FunctionComponent<AllAppointmentsPageProps> = () => {
  const { fetchAppointments, setDefaultFilterValues, setFilterValues } = useContext(AppointmentsContext);
  const { currentUserHasRole } = React.useContext(UserContext);

  let allAppointmentDefaultFilterValues = {
    'ransack[start_time_gteq]': today(),
    'ransack[start_time_lteq]': today(),
  };

  let sessionStorageFilterValues = JSON.parse(sessionStorage.getItem('appointmentState'))?.filterValues;

  let location = useLocation();
  let query = new URLSearchParams(location.search);
  const urlFilters = query.get('filters');

  const parseUrlFilters = (filters) => {
    if (!filters) return null;

    const standardizedFilters = filters.replace(/__/g, '&');

    return standardizedFilters.split('&').reduce((acc, pair) => {
      const [key, value] = pair.split('=');
      acc[decodeURIComponent(key)] = decodeURIComponent(value);
      return acc;
    }, {});
  };

  let parsedUrlFilters = urlFilters ? parseUrlFilters(urlFilters) : null;

  let filtersValuesToUse = parsedUrlFilters || sessionStorageFilterValues || allAppointmentDefaultFilterValues;

  useEffect(() => {
    setDefaultFilterValues(allAppointmentDefaultFilterValues);
    setFilterValues(filtersValuesToUse);
    fetchAppointments(filtersValuesToUse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    appointmentBaseStore.fetchChoices();
  }, []);

  useEffect(() => {
    if (currentUserHasRole('admin') || currentUserHasRole('system_owner')) {
      const intervalId = setInterval(() => {
        fetchAppointments();
      }, 30000);
      return () => clearInterval(intervalId);
    }
  }, [filtersValuesToUse, fetchAppointments]);

  return <AppointmentsListContainer showBulkActions />;
};

export default AllAppointmentsPage;
