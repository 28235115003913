import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import UpcomingSchoolClosings from './UpcomingSchoolClosings';
import RecentlyUpdatedStudents from './RecentlyUpdatedStudents';
import TimelineAppointmentCounter from './TimelineAppointmentCounter';
import ServiceProviderPicker from './ServiceProviderPicker';
import RadialChart from '../../dashboardCharts/components/RadialChart';
import TimelineVisualisation from '../../dashboardCharts/components/TimelineVisualisation';
import { UserContext } from '../../auth/contexts/userContext';
import { dashboardStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { ServiceFormOptionsContext } from '../../services/contexts/ServiceFormOptionsContext';
// import RenewalsOutOfComplianceTable from './RenewalsOutOfComplianceTable';

interface ServiceProviderDashboardProps {
  isLoading: boolean;
  serviceProvider: string;
  setServiceProvider: any;
}

const ServiceProviderDashboard: FunctionComponent<ServiceProviderDashboardProps> = (props) => {
  const { isLoading, serviceProvider, setServiceProvider } = props;
  const { radialValues, radialLabels } = useStoreObservable(dashboardStore);
  const { currentUserHasRole } = React.useContext(UserContext);
  const { fetchChoices } = React.useContext(ServiceFormOptionsContext);

  /* eslint-disable */
  React.useEffect(() => {
    fetchChoices();
  }, []);
  /* eslint-enable */

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div>
      {/* Is this still used, now that service providers have their own dashboard? */}
      {currentUserHasRole('admin') && (
        <ServiceProviderPicker serviceProvider={serviceProvider} setServiceProvider={setServiceProvider} />
      )}
      {!currentUserHasRole('school_admin') && (
        <TimelineAppointmentCounter
          ownerParam={serviceProvider === '0' ? '' : `ransack[user_id_eq]=${serviceProvider}__`}
        />
      )}
      <br />
      <Grid justify="space-between" container spacing={1}>
        <Grid item xs={12}>
          <TimelineVisualisation />
        </Grid>
      </Grid>
      <div className="radial-chart-container" style={{ margin: '-45px auto 35px', height: 240, width: 1150 }}>
        <div style={{ display: 'inline-block' }}>
          <RadialChart
            data={radialValues.ieps}
            label="IEPs"
            labelNumber={radialLabels.iep_label}
            link={`/renewals?filters=ransack[users_id_eq]=${serviceProvider}__ransack[active_eq]=true__ransack[renewal_type_id_eq]=1__`}
            chartSize="small"
          />
        </div>
        <div style={{ display: 'inline-block' }}>
          <RadialChart
            data={radialValues.referrals}
            label="Referrals"
            labelNumber={radialLabels.referral_label}
            link={`/referrals?filters=ransack[users_id_eq]=${serviceProvider}__ransack[status_eq]=active__ransack[phase_eq]=referral__`}
            chartSize="large"
          />
        </div>
        <div style={{ display: 'inline-block' }}>
          <RadialChart
            data={radialValues.rrs}
            label="RRs"
            labelNumber={radialLabels.rr_label}
            link={`/renewals?filters=ransack[users_id_eq]=${serviceProvider}__ransack[active_eq]=true__ransack[renewal_type_id_eq]=2__`}
            chartSize="small"
          />
        </div>
      </div>
      <br />
      {currentUserHasRole('school_admin') ? (
        <Grid justify="space-between" container spacing={3}>
          <Grid item xs={12}>
            <RecentlyUpdatedStudents />
          </Grid>
        </Grid>
      ) : (
        <Grid justify="space-between" container spacing={3}>
          <Grid item xs={6}>
            <UpcomingSchoolClosings />
          </Grid>
          <Grid item xs={6}>
            <RecentlyUpdatedStudents />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ServiceProviderDashboard;
