import React, { useState } from 'react';
import { axios } from '../../shared/singletons';
import { Appointment } from '../../appointments/model';
import { Renewal } from '../../renewals/model';

interface DashboardContextInterface {
  incompleteAppointmentsLoading?: boolean;
  fetchIncompleteNeedsRescheduledAppointments?: () => Promise<void>;
  incompleteNeedsRescheduledAppointments?: Appointment[];
  fetchCompletedIepMeetings?: () => Promise<void>;
  completedIepMeetings?: Appointment[];
  supervisorTeamMembersLoading?: boolean;
  supervisorTeamMembers?: any[];
  fetchSupervisorTeamMembers?: () => Promise<void>;
  productivityAlertsLoading?: boolean;
  productivityAlerts?: any[];
  fetchProductivityAlerts?: (string) => Promise<void>;

  noncompliantRenewalsLoading?: boolean;
  fetchNoncompliantRenewals?: () => Promise<void>;
  noncompliantRenewals?: Renewal[];
}

const DashboardContext = React.createContext<DashboardContextInterface>({});

const DashboardContextConsumer = DashboardContext.Consumer;
const DashboardContextProvider = ({ children }) => {
  const [incompleteAppointmentsLoading, setIncompleteAppointmentsLoading] = React.useState(false);
  const [incompleteNeedsRescheduledAppointments, setIncompleteNeedsRescheduledAppointments] = React.useState<
    Appointment[]
  >([]);
  const [completedIepMeetings, setCompletedIepMeetings] = React.useState<Appointment[]>([]);
  const [supervisorTeamMembersLoading, setSupervisorTeamMembersLoading] = React.useState(false);
  const [productivityAlertsLoading, setProductivityAlertsLoading] = useState(false);
  const [productivityAlerts, setProductivityAlerts] = useState([]);
  const [supervisorTeamMembers, setSupervisorTeamMembers] = React.useState([]);
  const [noncompliantRenewalsLoading, setNoncompliantRenewalsLoading] = React.useState(false);
  const [noncompliantRenewals, setNoncompliantRenewals] = React.useState<Renewal[]>([]);

  const fetchIncompleteNeedsRescheduledAppointments = async () => {
    setIncompleteAppointmentsLoading(true);

    await axios.get<string, any>('supervisor_dashboard/incomplete_needs_rescheduled_appointments.json').then((resp) => {
      setIncompleteNeedsRescheduledAppointments(resp?.data?.result);
    });
    setIncompleteAppointmentsLoading(false);
  };

  const fetchCompletedIepMeetings = async () => {
    setIncompleteAppointmentsLoading(true);

    await axios.get<string, any>('supervisor_dashboard/completed_meeting_participations.json').then((resp) => {
      setCompletedIepMeetings(resp?.data?.result);
    });
    setIncompleteAppointmentsLoading(false);
  };

  const fetchSupervisorTeamMembers = async () => {
    setSupervisorTeamMembersLoading(true);

    await axios.get<string, any>('supervisor_dashboard/team_members.json').then((resp) => {
      setSupervisorTeamMembers(resp?.data?.result);
    });
    setSupervisorTeamMembersLoading(false);
  };

  const fetchProductivityAlerts = async (userId) => {
    setProductivityAlertsLoading(true);

    await axios
      .get<string, any>('productivity_alerts.json', { params: { supervisor_id: userId } })
      .then((response) => {
        setProductivityAlerts(response?.data?.result);
      });
    setProductivityAlertsLoading(false);
  };

  const fetchNoncompliantRenewals = async () => {
    setNoncompliantRenewalsLoading(true);

    await axios.get<string, any>('renewals/out_of_compliance').then((resp) => {
      setNoncompliantRenewals(resp?.data?.renewals);
    });
    setNoncompliantRenewalsLoading(false);
  };

  return (
    <DashboardContext.Provider
      value={{
        incompleteAppointmentsLoading,
        fetchIncompleteNeedsRescheduledAppointments,
        incompleteNeedsRescheduledAppointments,
        fetchCompletedIepMeetings,
        completedIepMeetings,
        supervisorTeamMembersLoading,
        supervisorTeamMembers,
        fetchSupervisorTeamMembers,
        fetchProductivityAlerts,
        productivityAlerts,
        productivityAlertsLoading,
        noncompliantRenewalsLoading,
        fetchNoncompliantRenewals,
        noncompliantRenewals,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export { DashboardContextProvider, DashboardContextConsumer, DashboardContext };
