import React, { useState, useContext, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { FilterProps } from '../../shared/components/FilterBar';
import { UserContext } from '../../auth/contexts/userContext';
import AppointmentFilterBar from './AppointmentFilterBar';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import AppointmentBulkStatusChangeModal from './AppointmentBulkStatusChangeModal';
import EditCommentModal from './EditCommentModal';
import { serviceProviderAppointmentColumns, serviceProviderReadOnlyColumns, columnsByRole } from '../constants';

import AppointmentsList from './AppointmentsList';
import PrepareDownloadAppointmentsButton from './PrepareDownloadAppointmentsButton';

import AppointmentQuickStatusDeleteModal from './AppointmentQuickStatusDeleteModal';
import DeleteAppointmentConfirmModal from './DeleteAppointmentConfirmModal';
import DeleteFutureAppointmentsConfirmModal from './DeleteFutureAppointmentsConfirmModal';

// TODO: Pull the complete appointments separately from the incomplete appointments.
// Currently the page count for both tables shows the grand total, instead of the total for each table.

interface AppointmentsListContainerProps {
  showBulkActions?: boolean;
  filters?: FilterProps[];
}

const AppointmentsListContainer: React.FC<AppointmentsListContainerProps> = (props) => {
  const { showBulkActions = false, filters } = props;

  const { user, currentUserHasRole, currentUserHasAnyRole } = useContext(UserContext);
  const { fetchAppointments, appointments, totalAppointments, selectedRows, clearRowsSelection } = useContext(
    AppointmentsContext,
  );

  let [bulkUpdateModalOpen, setBulkUpdateModalOpen] = useState(false);
  let [allAppointments, setAllAppointments] = useState([]);
  let [incompleteAppointments, setIncompleteAppointments] = useState([]);
  let [submittedAppointments, setSubmittedAppointments] = useState([]);

  let columnsToUse = columnsByRole(user.roles[0]) || serviceProviderAppointmentColumns;

  useEffect(() => {
    setAllAppointments(appointments);
    setIncompleteAppointments(
      appointments.filter((appointment) => ['incomplete', 'needs_rescheduled', 'ticket'].includes(appointment.status)),
    );
    setSubmittedAppointments(
      appointments.filter((appointment) => !['incomplete', 'needs_rescheduled', 'ticket'].includes(appointment.status)),
    );
  }, [appointments]);

  const handleBulkUpdate = () => {
    setBulkUpdateModalOpen(true);
  };

  const onBulkUpdateSuccess = () => {
    fetchAppointments();
    clearRowsSelection();
  };

  return (
    <>
      <EditCommentModal
        onSuccess={() => {
          fetchAppointments();
        }}
      />
      <DeleteAppointmentConfirmModal />
      <DeleteFutureAppointmentsConfirmModal />
      <AppointmentBulkStatusChangeModal
        bulkUpdateModalOpen={bulkUpdateModalOpen}
        setBulkUpdateModalOpen={setBulkUpdateModalOpen}
        selectedRows={selectedRows}
        onSuccess={() => onBulkUpdateSuccess()}
      />
      <AppointmentQuickStatusDeleteModal />
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="h1" component="h1">
            Appointments
          </Typography>
        </Grid>
        <br />
        {showBulkActions && (
          <Grid item style={{ marginBottom: 20, display: 'flex' }}>
            {currentUserHasRole('admin') && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleBulkUpdate}
                disabled={selectedRows.length === 0}
                style={{ width: 155 }}
              >
                Bulk Update
              </Button>
            )}
            {currentUserHasAnyRole(['admin', 'accountant', 'services_coordinator']) && (
              <PrepareDownloadAppointmentsButton />
            )}
          </Grid>
        )}
        <Grid item xs={12} style={{ marginBottom: 30 }}>
          <AppointmentFilterBar filters={filters} />
        </Grid>
        {currentUserHasRole('service_provider') ? (
          <>
            <Grid item xs={12}>
              <Typography variant="h2" component="h2">
                Scheduled & Needs Rescheduled Appointments
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 30 }}>
              <AppointmentsList
                selectableRows={false}
                urlSuffix={'/session_notes/new'}
                records={incompleteAppointments}
                totalRecords={totalAppointments}
                columns={columnsToUse}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h2" component="h2">
                Submitted Appointments
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AppointmentsList
                selectableRows={false}
                urlSuffix={'/session_notes'}
                records={submittedAppointments}
                totalRecords={totalAppointments}
                columns={serviceProviderReadOnlyColumns}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <AppointmentsList
              selectableRows
              records={allAppointments}
              totalRecords={totalAppointments}
              columns={columnsToUse}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AppointmentsListContainer;
