import React, { ChangeEvent } from 'react';
import { Grid, Typography } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import Button, { ButtonFlavor } from '../../shared/components/Button';
import { StudentsContext } from '../../students/contexts/StudentsContext';
import { StudentFormValues, Student } from '../../students/model';
import { useStyles } from '../../shared/style/siteWideStyles';

interface CreateStudentFormProps {
  student?: Student;
  onSuccess?: (studentId: string) => void;
}

const CreateStudentForm: React.FunctionComponent<CreateStudentFormProps> = (props) => {
  const { student, onSuccess } = props;
  const classes = useStyles();
  const [formValues, setFormValues] = React.useState<StudentFormValues>({});
  const [choicesFetched, setChoicesFetched] = React.useState(false);

  const {
    createStudent,
    updateStudent,
    formErrors,
    fetchChoices,
    schoolChoices,
    gradeLevelChoices,
    locationChoices,
    fetchLocations,
  } = React.useContext(StudentsContext);

  React.useEffect(() => {
    setFormValues({ ...student, email_student: student?.email, esy_eligible: "false" });
  }, [setFormValues, student]);

  React.useEffect(() => {
    if (student?.payer_id || !choicesFetched) {
      fetchChoices(student?.payer_id);
      setChoicesFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student?.payer_id]);

  const payer_id = formValues?.payer_id;

  React.useEffect(() => {
    if (payer_id) {
      fetchLocations(payer_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payer_id]);

  const handleFormValueChange = (key: string, value: string) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const handleCreateStudent = () => {
    createStudent(formValues, onSuccess);
  };

  const handleUpdateStudent = () => {
    updateStudent(student.id, formValues, onSuccess);
  };

  return (
    <>
      <form noValidate autoComplete="off" style={{ marginBottom: '30px' }}>
        <Typography className={classes.formTitle} variant="h2" component="h2">
          Student Details
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <TextInput
              styleOverrides={classes.textInput}
              type="text"
              label="First Name"
              errorMessage={formErrors?.first_name}
              value={formValues?.first_name}
              valueChanged={(value) => handleFormValueChange('first_name', value.trim())}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              styleOverrides={classes.textInput}
              type="text"
              label="Last name"
              errorMessage={formErrors?.last_name}
              value={formValues?.last_name}
              valueChanged={(value) => handleFormValueChange('last_name', value.trim())}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="School"
              errorMessage={formErrors?.payer_id}
              value={formValues?.payer_id}
              choices={schoolChoices}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormValueChange('payer_id', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Preferred Location"
              errorMessage={formErrors?.location_id}
              value={formValues?.location_id}
              choices={locationChoices}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormValueChange('location_id', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              styleOverrides={classes.textInput}
              type="text"
              label="School-Issued ID"
              errorMessage={formErrors?.school_issued_id}
              value={formValues?.school_issued_id}
              valueChanged={(value) => handleFormValueChange('school_issued_id', value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              styleOverrides={classes.textInput}
              type="text"
              label="Teacher"
              errorMessage={formErrors?.teacher}
              value={formValues?.teacher}
              valueChanged={(value) => handleFormValueChange('teacher', value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              styleOverrides={classes.textInput}
              type="text"
              label="Room Number"
              errorMessage={formErrors?.room_number}
              value={formValues?.room_number}
              valueChanged={(value) => handleFormValueChange('room_number', value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Status"
              errorMessage={formErrors?.discharged}
              value={student?.id ? formValues?.discharged?.toString() : 'false'}
              choices={
                student?.id
                  ? [
                      { label: 'Active', value: 'false' },
                      { label: 'Discharged', value: 'true' },
                    ]
                  : [{ label: 'Active', value: 'false' }]
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormValueChange('discharged', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              styleOverrides={classes.textInput}
              type="date"
              shrinkLabel
              label="Date of Birth"
              errorMessage={formErrors?.birthdate}
              value={formValues?.birthdate}
              valueChanged={(value) => handleFormValueChange('birthdate', value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Grade Level"
              errorMessage={formErrors?.grade_level_id}
              value={formValues?.grade_level_id}
              choices={gradeLevelChoices}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormValueChange('grade_level_id', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              styleOverrides={classes.textInput}
              type="text"
              label="Email (optional)"
              errorMessage={formErrors?.email_student}
              value={formValues?.email_student}
              valueChanged={(value) => handleFormValueChange('email_student', value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              choices={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              label="ESY Eligible"
              errorMessage={formErrors?.esy_eligible}
              value={formValues?.esy_eligible}
              valueChanged={(value) => handleFormValueChange('esy_eligible', value)}
            />
          </Grid>
        </Grid>
      </form>
      {student.id ? (
        <Button
          text="Update Student"
          disabled={false}
          flavor={ButtonFlavor.Primary}
          handleClick={handleUpdateStudent}
        />
      ) : (
        <Button
          text="Create Student and Continue"
          disabled={false}
          flavor={ButtonFlavor.Primary}
          handleClick={handleCreateStudent}
        />
      )}
    </>
  );
};

export default CreateStudentForm;
