import React, { FunctionComponent, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Divider, ListItemIcon, MenuItem, Typography, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { _translateEach } from '../../shared/utils/translation.utils';
import CustomMaterialMenu from '../../shared/components/CustomMaterialMenu';
import { Appointment } from '../model';
import { AppointmentsContext } from '../contexts/AppointmentsContext';

interface AppointmentlRowMenuProps {
  appointment: Appointment;
}

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: '24px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
}));

const AppointmentRowMenu: FunctionComponent<AppointmentlRowMenuProps> = (props: AppointmentlRowMenuProps) => {
  const { appointment } = props;
  const classes = useStyles();
  const location = useLocation();

  const currentUrl = `${location.pathname}${location.search}`;

  const { setDestroyModalOpen, setDestroyFutureModalOpen, setAppointmentToDelete } = useContext(AppointmentsContext);

  const t = _translateEach({
    viewAppointment: 'appointments.rowMenu.viewAppointment',
    viewService: 'appointments.rowMenu.viewService',
    viewSessionNote: 'appointments.rowMenu.viewSessionNote',
    editAppointment: 'appointments.rowMenu.editAppointment',
    deleteAppointment: 'appointments.rowMenu.deleteAppointment',
    addAppointment: 'appointments.rowMenu.addAppointment',
    deleteConfirm: 'appointments.rowMenu.deleteConfirm',
  });

  const handleDestroyClicked = () => {
    setAppointmentToDelete(appointment);
    setDestroyModalOpen(true);
  };

  const handleDestroyFutureClicked = () => {
    setAppointmentToDelete(appointment);
    setDestroyFutureModalOpen(true);
  };

  return (
    <Box>
      <CustomMaterialMenu size="small">
        <div>
          <MenuItem>
            <a
              href={`/students/${appointment?.student_id}/appointments/${
                appointment?.id
              }/edit?return_to=${encodeURIComponent(currentUrl)}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <EditIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">Reschedule Appointment</Typography>
            </a>
          </MenuItem>
          <MenuItem>
            <a
              href={`/students/${appointment.student_id}/appointments/${appointment.id}/bulk-reschedule`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <EditIcon fontSize="small" color="secondary" />
              </ListItemIcon>
              <Typography variant="inherit">Reschedule Future Appointments</Typography>
            </a>
          </MenuItem>
        </div>
        <Box>
          <Divider />
          <MenuItem onClick={() => handleDestroyClicked()}>
            <ListItemIcon className={classes.listItemIcon}>
              <DeleteIcon fontSize="small" color="secondary" />
            </ListItemIcon>
            <Typography variant="inherit">{t.deleteAppointment}</Typography>
          </MenuItem>
          <MenuItem onClick={() => handleDestroyFutureClicked()}>
            <ListItemIcon className={classes.listItemIcon}>
              <DeleteIcon fontSize="small" color="secondary" />
            </ListItemIcon>
            <Typography variant="inherit">Delete All Future Appointments</Typography>
          </MenuItem>
        </Box>
      </CustomMaterialMenu>
    </Box>
  );
};

export default AppointmentRowMenu;
