import React from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { Box, Drawer, Link, List, ListItem, Typography, makeStyles } from '@material-ui/core';
import AppointmentIcon from '@material-ui/icons/DateRange';
import AddIcon from '@material-ui/icons/AddOutlined';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ReferralIcon from '@material-ui/icons/ListAlt';
import StudentIcon from '@material-ui/icons/SchoolOutlined';
import RenewalIcon from '@material-ui/icons/AvTimerOutlined';
import UserIcon from '@material-ui/icons/PersonOutlineOutlined';
import SchoolIcon from '@material-ui/icons/Business';
import Receipt from '@material-ui/icons/Receipt';
import Badge from '@mui/material/Badge';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { UserContext } from '../../auth/contexts/userContext';
import { Urls } from '../constants/urls';

const drawerWidth = 100;

const today = () => {
  return format(new Date(), 'yyyy-MM-dd');
};

const useStyles = makeStyles((_theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'fixed',
    top: '65px',
    display: 'table',
    height: 'calc(100% - 65px)',
  },
  list: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  link: {
    width: '100%',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  listItem: {
    textAlign: 'center',
    padding: '10px',
  },
}));

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { currentUserHasRole, user } = React.useContext(UserContext);

  const newReferralItem = {
    icon: <AddIcon style={{ width: '1.2em', height: '1.2em' }} />,
    text: 'New Referral',
    action: () => history.push('/referral_students/new'),
  };

  const invoicesItem = {
    icon: <Receipt />,
    text: 'Invoices',
    action: () => history.push('/invoices'),
  };

  const dashboardItem = {
    icon: <DashboardIcon />,
    text: 'Dashboard',
    action: () => history.push(Urls.DashboardRoute),
  };

  const studentsItem = {
    icon: <StudentIcon />,
    text: 'Students',
    action: () => {
      sessionStorage.setItem(
        'studentState',
        JSON.stringify({
          filterValues: {
            'ransack[discharged_eq]': 'false',
            'appointment_ransack[start_time_gteq]': today(),
            'appointment_ransack[start_time_lteq]': today(),
          },
        }),
      );
      history.push(Urls.StudentsRoute);
    },
  };

  const appointmentsItem = {
    icon: <AppointmentIcon />,
    text: 'Appointments',
    action: () => {
      sessionStorage.setItem(
        'appointmentState',
        JSON.stringify({
          filterValues: {
            'ransack[start_time_gteq]': today(),
            'ransack[start_time_lteq]': today(),
          },
        }),
      );
      history.push(Urls.AppointmentsRoute);
    },
  };

  const servicesItem = {
    icon: <ReferralIcon />,
    text: 'Referrals',
    action: () => {
      sessionStorage.setItem(
        'serviceState',
        JSON.stringify({
          filterValues: {
            'ransack[status_eq]': 'active',
            'appointment_ransack[start_time_gteq]': today(),
            'appointment_ransack[start_time_lteq]': today(),
          },
        }),
      );
      history.push('/referrals');
    },
  };

  const renewalsItem = {
    icon: <RenewalIcon />,
    text: 'Renewals',
    action: () => {
      sessionStorage.setItem(
        'renewalState',
        JSON.stringify({
          filterValues: {
            'ransack[active_eq]': 'true',
          },
        }),
      );
      history.push(Urls.RenewalsRoute);
    },
  };

  const usersItem = {
    icon: <UserIcon />,
    text: 'Users',
    action: () => {
      sessionStorage.setItem(
        'userState',
        JSON.stringify({
          filterValues: {
            'ransack[inactivated_at_null]': '1',
          },
        }),
      );
      history.push(Urls.UsersRoute);
    },
  };

  const schoolsItem = {
    icon: <SchoolIcon />,
    text: 'Schools',
    action: () => {
      sessionStorage.setItem(
        'schoolState',
        JSON.stringify({
          filterValues: {
            'ransack[discharged_eq]': 'false',
          },
        }),
      );
      history.push(Urls.SchoolsRoute);
    },
  };

  const ticketsItem = {
    icon: <FeedbackIcon />,
    text: 'Tickets',
    action: () => history.push('/user_tickets'),
  };

  const errorBadge = (item, user) => {
    switch (item.text) {
      case 'Tickets':
        return (
          <Badge color="error" badgeContent={user.ticket_count}>
            {item.icon}
          </Badge>
        );
      case 'Appointments':
        // This verison of Material UI (v4) does not suport "success" as a Badge color.
        // It will hide the dot on unknown colors, so `invisible=false` is needed to ensure the dot's visibility.
        const color = user.appointment_count === 0 ? 'success' : 'error';
        return (
          <Badge color={color} invisible={false} badgeContent={user.appointment_count}>
            {item.icon}
          </Badge>
        );
      default:
        return item.icon;
    }
  };

  let itemsByRole = [];

  if (currentUserHasRole('system_owner')) {
    itemsByRole = [
      dashboardItem,
      studentsItem,
      appointmentsItem,
      servicesItem,
      renewalsItem,
      schoolsItem,
      ticketsItem,
      invoicesItem,
    ];
  } else if (currentUserHasRole('admin')) {
    itemsByRole = [
      dashboardItem,
      studentsItem,
      appointmentsItem,
      servicesItem,
      renewalsItem,
      usersItem,
      schoolsItem,
      ticketsItem,
    ];
  } else if (currentUserHasRole('accountant')) {
    itemsByRole = [dashboardItem, studentsItem, appointmentsItem, servicesItem, schoolsItem, invoicesItem];
  } else if (currentUserHasRole('services_coordinator')) {
    itemsByRole = [dashboardItem, studentsItem, appointmentsItem, servicesItem, renewalsItem];
  } else if (currentUserHasRole('supervisor')) {
    itemsByRole = [dashboardItem, studentsItem, appointmentsItem, servicesItem, renewalsItem, usersItem, ticketsItem];
  } else if (currentUserHasRole('service_provider')) {
    itemsByRole = [dashboardItem, studentsItem, appointmentsItem, servicesItem, renewalsItem, ticketsItem];
  } else if (currentUserHasRole('school_admin')) {
    itemsByRole = [newReferralItem, dashboardItem, studentsItem, servicesItem];
  }

  return (
    <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <List className={classes.list}>
          {itemsByRole.map((item) => (
            <ListItem button className={classes.listItem} key={item.text}>
              <Link className={classes.link} onClick={item.action}>
                {errorBadge(item, user)}
                <Typography variant="subtitle2">{item.text}</Typography>
              </Link>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
