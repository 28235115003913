import React, { FunctionComponent, useContext, useEffect } from 'react';
import SelectInput from '../../shared/components/form/SelectInput';
import { userSelectOptionStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { useStyles } from '../../shared/style/siteWideStyles';
import { InternalUsersContext } from '../../internalUsers/contexts/InternalUsersContext';

const UserSelectableOptionsForm: FunctionComponent = () => {
  const classes = useStyles();

  const { supervisorChoices } = useStoreObservable(userSelectOptionStore);
  const { formValues, setFormValues, formErrors } = useContext(InternalUsersContext);

  useEffect(() => {
    userSelectOptionStore.fetchChoices();
  }, []);

  return (
    <SelectInput
      styleOverrides={classes.textInput}
      type="text"
      label="Supervisor"
      errorMessage={formErrors?.supervisor}
      value={formValues?.supervisor_id}
      valueChanged={(value: string) => setFormValues({ ...formValues, supervisor_id: value })}
      choices={supervisorChoices}
      defaultChoice={{ value: '', label: 'None' }}
    />
  );
};

export default UserSelectableOptionsForm;
