import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { Student } from '../model';

interface StudentCustomActionsProps {
  student: Student;
}

const StudentCustomActions: FunctionComponent<StudentCustomActionsProps> = (props) => {
  const { student } = props;
  const { discharged } = student;

  const history = useHistory();

  return (
    <>
      <Card>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Annual Updater
              </Typography>
              <Typography>
                Wizard for updating this student's attachments, services, appointments, goals, and renewals.
              </Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(`/students/${student.id}/annual_updater_attachments`)}
              >
                Annual Updater
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ marginTop: 30 }}>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Discharge Student
              </Typography>
              <Typography>
                Discharge all active services and renewals and the student themselves on this page.
              </Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(`/students/${student.id}/discharge`)}
                disabled={Boolean(discharged)}
              >
                Go to Discharge Page
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ marginTop: 30 }}>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Reactivate Student
              </Typography>
              <Typography>Reactivate services and renewals and the student themselves on this page.</Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(`/students/${student.id}/reactivate`)}
                disabled={!Boolean(discharged)}
              >
                Go to Reactivation Page
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ marginTop: 30 }}>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="h2" component="h2">
                Delete Student
              </Typography>
              <Typography>
                Permanently delete student from the timeline site. This cannot be undone, and in most cases students
                should be discharged instead of deleted.
              </Typography>
              <br />
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: '#F44F64' }}
                onClick={() => history.push(`/students/${student.id}/delete`)}
              >
                GO TO DELETE PAGE
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default StudentCustomActions;
