import React, { useState, Dispatch, SetStateAction } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import axios from '../../shared/utils/axios.utils';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { alertStore } from '../../shared/singletons';

import { Invoice } from '../model';

interface DownloadInvoiceExcelFileProps {
  invoice: Invoice;
  stepIndex: number;
  fetchInvoice: () => void;
  setInvoice: Dispatch<SetStateAction<Invoice>>;
}

const DownloadInvoiceExcelFile: React.FC<DownloadInvoiceExcelFileProps> = (props) => {
  let { invoice, setInvoice, stepIndex, fetchInvoice } = props;

  let [invoiceDownloading, setInvoiceDownloading] = useState(false);

  const downloadInvoiceReport = async () => {
    setInvoiceDownloading(true);
    axios
      .get(`/invoices/invoices/${invoice?.id}/report`, {
        params: { step_index: stepIndex },
      })
      .then((response) => {
        showDownloadAsyncNotification(response?.data?.message);
        setInvoiceDownloading(false);
        fetchInvoice();
      })
      .catch((error) => console.error('Error downloading report:', error));
  };

  const showDownloadAsyncNotification = (message) => {
    alertStore.alertSuccess(message);
  };

  let handleResetCheckmark = async () => {
    let response = await axios.put(`/invoices/invoices/${invoice?.id}`, {
      step_index: props.stepIndex,
      reset: 'true',
      invoice: { status: 'Review Needed' },
    });
    setInvoice(response.data.result);
  };

  return invoiceDownloading ? (
    <LoadingSpinner />
  ) : (
    <Grid container>
      <Button variant="contained" color="primary" onClick={downloadInvoiceReport}>
        Download Excel File and Mark "Under Review"
      </Button>
      <Button
        variant="contained"
        color="secondary"
        style={{ marginLeft: 20 }}
        onClick={handleResetCheckmark}
        disabled={!invoice?.completed_steps?.includes(stepIndex.toString())}
      >
        Reset Checkmark and Revert to "Review Needed"
      </Button>
    </Grid>
  );
};

export default DownloadInvoiceExcelFile;
