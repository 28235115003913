import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Student, StudentResponse, StudentStatusDetails } from '../model';
import { axios, alertStore } from '../../shared/singletons';
import { API_ENDPOINT } from '../../../config/env';
import { School } from '../../schools/model';

interface DischargeStudentContextInterface {
  student?: Student;
  setStudent?: (student: Student) => void;
  studentsToDischarge?: StudentStatusDetails[];
  setStudentsToDischarge?: (student: StudentStatusDetails[]) => void;
  fetchStudentActiveServices?: (studentId: string) => Promise<void>;
  dischargeStudent?: (studentId: string) => Promise<void>;
  studentLoading?: boolean;
  dischargeService?: (serviceId: string) => Promise<void>;
  dischargeRenewal?: (renewalId: string, onDischarge?: () => void) => Promise<void>;
  dischargeSchoolModalOpen?: boolean;
  setDischargeSchoolModalOpen?: (open: boolean) => void;
  dischargeStudentsModalOpen?: boolean;
  setDischargeStudentsModalOpen?: (open: boolean) => void;
  relevantStudents?: Student[];
  totals?: {
    total_active_students: number;
    total_active_services: number;
    total_active_appointments: number;
    total_active_renewals: number;
  };
  fetchPreDischargeData?: (schoolId: string) => void;
  schoolToDischarge?: School;
  setSchoolToDischarge?: (school: School) => void;
}

const DischargeStudentContext = React.createContext<DischargeStudentContextInterface>({});
const DischargeStudentContextConsumer = DischargeStudentContext.Consumer;

const DischargeStudentContextProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [schoolToDischarge, setSchoolToDischarge] = useState<School>({});
  const [student, setStudent] = useState<Student>({});
  const [studentsToDischarge, setStudentsToDischarge] = useState<StudentStatusDetails[]>([]);
  const [studentLoading, setStudentLoading] = useState(false);
  const [dischargeSchoolModalOpen, setDischargeSchoolModalOpen] = useState(false);
  const [dischargeStudentsModalOpen, setDischargeStudentsModalOpen] = useState(false);
  const [relevantStudents, setRelevantStudents] = useState<Student[]>([]);
  const [totals, setTotals] = useState({
    total_active_students: null,
    total_active_services: null,
    total_active_appointments: null,
    total_active_renewals: null,
  });

  const fetchStudentActiveServices = async (studentId: string) => {
    setStudentLoading(true);

    axios(`${API_ENDPOINT}/discharge_patient/${studentId}`)
      .then((result) => {
        setStudentLoading(false);
        setStudent(result?.data?.result);
      })
      .catch(() => {
        setStudentLoading(false);
        alertStore.alertError('Unable to load student, please try again');
      });
  };

  const dischargeService = async (serviceId: string) => {
    try {
      await axios
        .put<string, StudentResponse>(`${API_ENDPOINT}/discharge_patient/${serviceId}/discharge_service`, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((r) => {
          const updatedServices = student.active_services.filter((service) => service.id !== serviceId);
          setStudent({ ...student, active_services: updatedServices });
          alertStore.alertSuccess('Service discharged');
        });
    } catch {
      alertStore.alertError('Unable to discharge service, please try again');
    }
  };

  const dischargeRenewal = async (renewalId: string, onDischarge?: () => void) => {
    try {
      await axios
        .put<string, StudentResponse>(`${API_ENDPOINT}/discharge_patient/${renewalId}/discharge_renewal`, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((r) => {
          alertStore.alertSuccess('Renewal discharged');

          if (onDischarge) {
            onDischarge();
          } else {
            const updatedRenewals = student.active_renewals.filter((service) => service.id !== renewalId);
            setStudent({ ...student, active_renewals: updatedRenewals });
          }
        });
    } catch {
      alertStore.alertError('Unable to dischage renewal, please try again');
    }
  };

  const fetchPreDischargeData = (schoolId: string) => {
    axios.get(`/discharge_schools/${schoolId}/edit`).then((response) => {
      setSchoolToDischarge(response.data?.school);
      setTotals(response.data?.totals);
      setRelevantStudents(response.data?.result);
    });
  };

  const dischargeStudent = async (studentId: string) => {
    try {
      await axios
        .put<string, StudentResponse>(`${API_ENDPOINT}/discharge_patient/${studentId}/discharge_patient`, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((r) => {
          history.push('/students');
          alertStore.alertSuccess('Student discharged');
          history.push(`/students/${studentId}/discharge/complete`);
        });
    } catch {
      alertStore.alertError('Unable to dischage student, please try again');
    }
  };

  return (
    <DischargeStudentContext.Provider
      value={{
        schoolToDischarge,
        setSchoolToDischarge,
        student,
        setStudent,
        studentsToDischarge,
        setStudentsToDischarge,

        dischargeSchoolModalOpen,
        setDischargeSchoolModalOpen,
        dischargeStudentsModalOpen,
        setDischargeStudentsModalOpen,

        studentLoading,
        dischargeStudent,
        fetchStudentActiveServices,
        dischargeService,
        dischargeRenewal,

        relevantStudents,
        totals,
        fetchPreDischargeData,
      }}
    >
      {children}
    </DischargeStudentContext.Provider>
  );
};

export { DischargeStudentContextProvider, DischargeStudentContextConsumer, DischargeStudentContext };
