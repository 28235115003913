import React, { FunctionComponent } from 'react';
import { Button, Card, CardContent, Typography, Grid, makeStyles } from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { Service } from '../../services/model';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { ReactivateStudentContext } from '../contexts/ReactivateStudentContext';

interface ReactivateServiceCardProps {
  service: Service;
  serviceLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: '24px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
}));

const ReactivateServiceCard: FunctionComponent<ReactivateServiceCardProps> = (props) => {
  const { service, serviceLoading } = props;
  const classes = useStyles();
  const { reactivateService, reactivateServiceAndAppointments } = React.useContext(ReactivateStudentContext);

  const [reactiveServiceModalOpen, setReactivateServiceOnlyModalOpen] = React.useState(false);
  const [reactiveServiceAndAppointmentsModalOpen, setReactivateServiceAndAppointmentsModalOpen] = React.useState(false);

  const handleReactivateService = () => {
    reactivateService(service?.id);
    setReactivateServiceOnlyModalOpen(false);
  };

  const handleReactivateServiceAndAppointments = () => {
    reactivateServiceAndAppointments(service?.id);
    setReactivateServiceAndAppointmentsModalOpen(false);
  };

  return serviceLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <DeleteModal
        isOpen={reactiveServiceModalOpen}
        openStatusChanged={setReactivateServiceOnlyModalOpen}
        onDelete={() => handleReactivateService()}
        confirmLabel="Confirm"
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          Are you sure you want to reactivate this service?
        </Typography>
      </DeleteModal>
      <DeleteModal
        isOpen={reactiveServiceAndAppointmentsModalOpen}
        openStatusChanged={setReactivateServiceAndAppointmentsModalOpen}
        onDelete={() => handleReactivateServiceAndAppointments()}
        confirmLabel="Confirm"
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          Are you sure you want to reactivate this service and {service?.future_appointments_count} future appointments?
        </Typography>
      </DeleteModal>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              {`${service?.service_type} Information`}
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={6} md={3}>
            <InformationField
              label="Phase"
              value={service?.phase === 'patient' ? 'STUDENT' : service?.phase?.toUpperCase()}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label="Status" value={service?.status?.toUpperCase()} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label="Stage" value={service?.stage} />
          </Grid>
          <Grid item md={3} />
          <Grid item xs={6} md={3}>
            <InformationField label="Last Updated" value={service?.updated_at} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label="Owner" value={service?.owner} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label="Start Date" value={service?.start_date} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label="End Date" value={service?.end_date} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label="Frequency" value={service?.frequency} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label="Future Archived Appointments" value={service?.future_appointments_count} />
          </Grid>
        </Grid>
        <br />
        <Button
          variant="contained"
          style={{ marginLeft: 7 }}
          color="primary"
          onClick={() => setReactivateServiceOnlyModalOpen(true)}
        >
          Reactivate Service
        </Button>
        {service?.future_appointments_count > 0 && (
          <Button
            variant="contained"
            style={{ marginLeft: 7 }}
            color="primary"
            onClick={() => setReactivateServiceAndAppointmentsModalOpen(true)}
          >
            Reactivate Service &  {service?.future_appointments_count} Future Appointments
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default ReactivateServiceCard;
