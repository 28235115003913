import React, { FunctionComponent, useState } from 'react';
import { Box, Grid, Typography, FormGroup, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import SelectInput from '../../shared/components/form/SelectInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { serviceFormStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { UserContext } from '../../auth/contexts/userContext';
import { ServiceFormOptionsContext } from '../../services/contexts/ServiceFormOptionsContext';
import { Service } from '../model';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px 0',
  },
  baseError: {
    marginBottom: '10px',
  },
});

interface ServiceCategoryFormProps {
  service: Service;
}

const ServiceCategoryForm: FunctionComponent<ServiceCategoryFormProps> = (props) => {
  const { service } = props;
  const classes = useStyles();

  const { currentUserHasRole } = React.useContext(UserContext);
  const {
    phaseChoices,
    serviceTypeChoices,
    stageChoices,
    ownerChoices,
    statusChoices,
    connectedServiceChoices,
  } = React.useContext(ServiceFormOptionsContext);

  const { formErrorAlertVisible, formErrors, formValues } = useStoreObservable(serviceFormStore);

  const [connectedServices, setConnectedServices] = useState(connectedServiceChoices);

  const handleFormValueChange = (key: string, value: string) => {
    if (key === 'referral_service_users_attributes') {
      if (formValues.referral_service_users_attributes === undefined) {
        formValues.referral_service_users_attributes = {};
      }

      // The logic and array manipulation here is hard to understand but it is all needed to work
      // with Rails accepts_nested_attributes_for.
      const user_id = value;
      let { referral_service_users_attributes } = formValues;

      if (
        formValues.referral_service_users_attributes?.[user_id] &&
        formValues.referral_service_users_attributes?.[user_id]?._destroy !== '1'
      ) {
        referral_service_users_attributes = {
          ...referral_service_users_attributes,
          [user_id]: { _destroy: '1', id: referral_service_users_attributes[user_id].id },
        };
      } else {
        referral_service_users_attributes = {
          ...referral_service_users_attributes,
          [user_id]: { id: referral_service_users_attributes?.[user_id]?.id, user_id },
        };
      }

      serviceFormStore.setFormValue({ ...formValues, referral_service_users_attributes });
    } else {
      serviceFormStore.setFormValue({ ...formValues, [key]: value });
    }
  };

  React.useEffect(() => {
    setConnectedServices(service.connected_service_options);
  }, [service]);

  return (
    <form noValidate autoComplete="off">
      {formErrors?.base && (
        <ErrorAlert
          display={formErrorAlertVisible}
          onClose={() => serviceFormStore.setState({ formErrorAlertVisible: false })}
        >
          {formErrors?.base}
        </ErrorAlert>
      )}
      {currentUserHasRole('school_admin') ? (
        <SelectInput
          styleOverrides={classes.textInput}
          type="text"
          label="Phase"
          errorMessage={formErrors?.phase}
          value="referral"
          valueChanged={(value: string) => handleFormValueChange('phase', value)}
          choices={[{ label: 'Referral', value: 'referral' }]}
        />
      ) : (
        <SelectInput
          styleOverrides={classes.textInput}
          type="text"
          label="Phase"
          errorMessage={formErrors?.phase}
          value={formValues?.phase}
          valueChanged={(value: string) => handleFormValueChange('phase', value)}
          choices={phaseChoices}
          defaultChoice={{ value: '', label: 'None' }}
        />
      )}
      {currentUserHasRole('school_admin') ? (
        <SelectInput
          styleOverrides={classes.textInput}
          type="text"
          label="Status"
          errorMessage={formErrors?.status}
          value={formValues?.status}
          valueChanged={(value: string) => handleFormValueChange('status', value)}
          choices={[{ value: 'active', label: 'Active' }]}
        />
      ) : (
        <SelectInput
          styleOverrides={classes.textInput}
          type="text"
          label="Status"
          errorMessage={formErrors?.status}
          value={formValues?.status}
          valueChanged={(value: string) => handleFormValueChange('status', value)}
          choices={statusChoices}
          defaultChoice={{ value: '', label: 'None' }}
        />
      )}
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Service Type"
        errorMessage={formErrors?.service}
        value={formValues?.service_id}
        valueChanged={(value: string) => {
          handleFormValueChange('service_id', value);
        }}
        choices={serviceTypeChoices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Connected Service"
        errorMessage={formErrors?.connected_service_id}
        value={formValues?.connected_service_id}
        valueChanged={(value: string) => {
          handleFormValueChange('connected_service_id', value);
        }}
        choices={connectedServices}
        defaultChoice={{ value: '', label: 'None' }}
      />
      {currentUserHasRole('admin') && (
        <SelectInput
          styleOverrides={classes.textInput}
          type="text"
          label="Stage"
          errorMessage={formErrors?.referral_stage}
          value={formValues?.referral_stage_id}
          valueChanged={(value: string) => handleFormValueChange('referral_stage_id', value)}
          choices={stageChoices}
          defaultChoice={{ value: '', label: 'None' }}
        />
      )}
      {currentUserHasRole('admin') && (
        <SelectInput
          styleOverrides={classes.textInput}
          type="text"
          label="ESY Eligible"
          value={formValues?.esy_scheduling_active?.toString()}
          valueChanged={(value: string) => handleFormValueChange('esy_scheduling_active', value)}
          choices={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
        />
      )}
      {currentUserHasRole('admin') && ownerChoices && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h3" component="h3">
              Providers
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup row>
              <Grid container>
                {ownerChoices.map((owner: any) => (
                  <Grid component={Box} key={owner.value} item xs={12} md={6} display="inherit">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={owner.label}
                          value={owner.value}
                          color="primary"
                          onChange={() => handleFormValueChange('referral_service_users_attributes', owner.value)}
                        />
                      }
                      label={owner.label}
                      disabled={
                        (formValues?.referral_service_users_attributes?.[owner?.value] as any)?.appointment_count > 0
                      }
                      checked={
                        formValues?.referral_service_users_attributes?.[owner?.value] !== undefined &&
                        formValues?.referral_service_users_attributes?.[owner?.value]?._destroy !== '1'
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default ServiceCategoryForm;
