import React from 'react';
import { AcademicYearsContextProvider } from './modules/schools/contexts/AcademicYearsContext';
import { AnnualUpdatesContextProvider } from './modules/annualUpdater/contexts/AnnualUpdatesContext';
import { AppointmentsContextProvider } from './modules/appointments/contexts/AppointmentsContext';
import { CaseLoadManagementContextProvider } from './modules/users/contexts/CaseLoadManagementContext';
import { DashboardContextProvider } from './modules/dashboard/contexts/DashboardContext';
import { DischargeStudentContextProvider } from './modules/students/contexts/DischargeStudentContext';
import { ReactivateStudentContextProvider } from './modules/students/contexts/ReactivateStudentContext';
import { DuplicateAppointmentsContextProvider } from './modules/duplicateAppointments/contexts/DuplicateAppointmentsContext';
import { GoalsContextProvider } from './modules/goals/contexts/GoalsContext';
import { ListingServicesContextProvider } from './modules/admin/listingServices/contexts/ListingServicesContext';
import { ReferralsContextProvider } from './modules/referrals/contexts/ReferralsContext';
import { RenewalsContextProvider } from './modules/renewals/contexts/RenewalsContext';
import { RenewalOptionsContextProvider } from './modules/renewals/contexts/RenewalOptionsContext';
import { RenewalStagesContextProvider } from './modules/admin/renewalStages/contexts/RenewalStagesContext';
import { ReportsContextProvider } from './modules/reports/contexts/ReportsContext';
import { SchoolsContextProvider } from './modules/schools/contexts/SchoolsContext';
import { SchoolActionsContextProvider } from './modules/schoolActions/contexts/SchoolActionsContext';
import { SchoolClosingContextProvider } from './modules/schoolClosings/contexts/SchoolClosingsContext';
import { ServiceFormOptionsContextProvider } from './modules/services/contexts/ServiceFormOptionsContext';
import { ServicesContextProvider } from './modules/services/contexts/ServicesContexts';
import { StudentsContextProvider } from './modules/students/contexts/StudentsContext';
import { UserContextProvider } from './modules/auth/contexts/userContext';
import { TrainingsContextProvider } from './modules/trainings/contexts/TrainingsContext';
import { JoyrideContextProvider } from './modules/joyride/contexts/JoyrideContext';
import { UserTicketsContextProvider } from './modules/userTickets/contexts/UserTicketsContext';
import { InternalUsersContextProvider } from './modules/internalUsers/contexts/InternalUsersContext';

const Contexts: React.FC = ({ children }) => {
  return (
    <AcademicYearsContextProvider>
      <AnnualUpdatesContextProvider>
        <AppointmentsContextProvider>
          <CaseLoadManagementContextProvider>
            <DashboardContextProvider>
              <DischargeStudentContextProvider>
                <ReactivateStudentContextProvider>
                  <DuplicateAppointmentsContextProvider>
                    <GoalsContextProvider>
                      <ListingServicesContextProvider>
                        <ReferralsContextProvider>
                          <RenewalsContextProvider>
                            <RenewalOptionsContextProvider>
                              <RenewalStagesContextProvider>
                                <ReportsContextProvider>
                                  <SchoolsContextProvider>
                                    <SchoolActionsContextProvider>
                                      <SchoolClosingContextProvider>
                                        <ServiceFormOptionsContextProvider>
                                          <ServicesContextProvider>
                                            <StudentsContextProvider>
                                              <UserContextProvider>
                                                <TrainingsContextProvider>
                                                  <JoyrideContextProvider>
                                                    <UserTicketsContextProvider>
                                                      <InternalUsersContextProvider>
                                                        {children}
                                                      </InternalUsersContextProvider>
                                                    </UserTicketsContextProvider>
                                                  </JoyrideContextProvider>
                                                </TrainingsContextProvider>
                                              </UserContextProvider>
                                            </StudentsContextProvider>
                                          </ServicesContextProvider>
                                        </ServiceFormOptionsContextProvider>
                                      </SchoolClosingContextProvider>
                                    </SchoolActionsContextProvider>
                                  </SchoolsContextProvider>
                                </ReportsContextProvider>
                              </RenewalStagesContextProvider>
                            </RenewalOptionsContextProvider>
                          </RenewalsContextProvider>
                        </ReferralsContextProvider>
                      </ListingServicesContextProvider>
                    </GoalsContextProvider>
                  </DuplicateAppointmentsContextProvider>
                </ReactivateStudentContextProvider>
              </DischargeStudentContextProvider>
            </DashboardContextProvider>
          </CaseLoadManagementContextProvider>
        </AppointmentsContextProvider>
      </AnnualUpdatesContextProvider>
    </AcademicYearsContextProvider>
  );
};

export default Contexts;
