import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import InformationField from '../../shared/components/InformationField';
import { ReactivateStudentContext } from '../contexts/ReactivateStudentContext';
import ReactivateServiceCard from '../components/ReactivateServiceCard';
import ReactivateRenewalCard from '../components/ReactivateRenewalCard';

const StudentReactivatePage: React.FunctionComponent = () => {
  const { studentId } = useParams<Record<string, string>>();
  const [studentFetched, setStudentFetched] = React.useState(false);
  const { fetchStudentInactiveServices, student, studentLoading, reactivateStudent } = React.useContext(
    ReactivateStudentContext,
  );

  React.useEffect(() => {
    if (!studentFetched) {
      fetchStudentInactiveServices(studentId);
      setStudentFetched(true);
    }
  }, [studentFetched, setStudentFetched, fetchStudentInactiveServices, studentId]);

  const handleReactivateStudent = () => {
    reactivateStudent(student.id);
  };

  return studentLoading ? (
    <p>Loading...</p>
  ) : (
    <>
      <h1 className="MuiTypography-root MuiTypography-h1">{`Reactivate ${student.first_name} ${student.last_name}`}</h1>
      <Grid container flex-direction="column">
        <Grid item xs={3}>
          <InformationField label="Timeline ID" value={student.slug} />
        </Grid>
        <Grid item xs={3}>
          <InformationField label="Student Name" value={`${student.first_name} ${student.last_name}`} />
        </Grid>
        <Grid item xs={3}>
          <InformationField label="School Name" value={student.school} />
        </Grid>
      </Grid>
      <br />

      <Button
        variant="contained"
        style={{ margin: '15px 0' }}
        color="primary"
        onClick={() => handleReactivateStudent()}
      >
        Reactivate Student
      </Button>
      <br />

      {student?.discharged_services?.length > 0 && (
        <>
          <h1 className="MuiTypography-root MuiTypography-h1">Discharged Services</h1>
          <div style={{ marginTop: '20px' }}>
            {student?.discharged_services?.map((service) => (
              <>
                <ReactivateServiceCard
                  service={service}
                  serviceLoading={false}
                />
                <br />
              </>
            ))}
          </div>
        </>
      )}
      {student?.discharged_renewals?.length > 0 && (
        <>
          <Typography variant="h1" component="h1">
            Discharged Renewals
          </Typography>
          <div style={{ marginTop: '20px' }}>
            {student?.discharged_renewals?.map((renewal: any) => (
              <>
                <ReactivateRenewalCard
                  renewal={renewal}
                  renewalLoading={false}
                />
                <br />
              </>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default StudentReactivatePage;
