import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import { Button, Card, CardContent, Grid, Typography, makeStyles } from "@material-ui/core";
import { ArrowBack, ArrowForward} from "@material-ui/icons";

import { axios, studentRecordStore } from "../../shared/singletons";
import { useStoreObservable } from "../../shared/state/useStoreObservable.hook";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import { IDataTableColumn } from "../../shared/components/DataTable/DataTable/model";
import DataTable from "../../shared/components/DataTable";
import { SchoolsContext } from "../../schools/contexts/SchoolsContext";
import { API_ENDPOINT } from "../../../config/env";


interface ActivateStudentEsyPageProps {
  schoolId?: string;
}

const useStyles = makeStyles({
  textInput: {
    height: "auto",
    width: 300,
    margin: "0",
  },
  baseError: {
    marginBottom: "10px",
  },
  link: {
    textDecoration: "none",
  },
});

const ActivateStudentEsyPage: FunctionComponent<ActivateStudentEsyPageProps> = (props) => {
  const classes = useStyles();
  const { school } = React.useContext(SchoolsContext);
  const { schoolId } = useParams<Record<string, string>>();
  const { records, recordsLoading } = useStoreObservable(studentRecordStore);
  const [ selectedStudents, setSelectedStudents] = useState(null);

  useEffect(() => {
    let filterValues = {
      "ransack[payer_id_eq]": schoolId?.toString(),
      "ransack[active_eq]": "true",
      "per": "1000"
    }
    studentRecordStore.setFilterValues(filterValues)
    studentRecordStore.fetchAllRecords();
  }, [schoolId]);

  const columns: IDataTableColumn<Record<string, unknown>>[] = [
    {
      name: "NAME",
      selector: "student_name",
      sortable: true,
    },
    {
      name: "TIMELINE ID",
      selector: "slug",
      sortable: true,
    },
  ];

  const updateSelectedRows = (updatedSelection) => {
    setSelectedStudents(updatedSelection);

    let selectedIds = updatedSelection.selectedRows.map(r => r.id);
    records.forEach(r => r.esy_scheduling_active = selectedIds.includes(r.id));
  }

  const checkRowSelected = (row) => {
    return row.esy_scheduling_active
  }

  const submitChanges = () => {
    const students = records.map(r => {
      return {
        id: r.id,
        esy_scheduling_active: r.esy_scheduling_active
      }
    })

    axios.put(`${API_ENDPOINT}/payers/${schoolId}/school_esy_schedulers/bulk_activate`, { students })
      .then((result) => {
        navigateToEsyScheduling();
      })
  }

  const navigateToEsyScheduling = () => {
    window.location.href = `/schools/${schoolId}/esy_scheduling/scheduling`;
  }

  return recordsLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12}>
          <Typography variant="h1" component="h1">
            {`Schedule ESY Appointments for ${school?.name}`}
          </Typography>

          <Link to="/users" className={classes.link}>
              <Grid alignItems="center" container spacing={1}>
                <Grid item>
                  <ArrowBack color="primary" style={{ display: "inherit" }} />
                </Grid>
                <Grid item>
                  <Typography color="primary" variant="body1">
                    Back To Users
                  </Typography>
                </Grid>
              </Grid>
            </Link>
        </Grid>

        { school?.error_message ? (
          <Grid item xs={12}>
            <Typography variant="h1" component="h1" align="center" style={{ marginTop: 50 }}>
              {school?.error_message}
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={9} style={{ alignContent: "center" }}>
            <Card>
              <CardContent style={{ marginBottom: "10px" }}>
                <Grid container style={{ justifyContent: "space-between" }}>
                  <Grid item>
                    <Typography variant="h2" component="h2">Select Students for ESY Scheduling</Typography>
                    <Typography variant="h4" component="h4">{ selectedStudents?.selectedCount || 0 } selected</Typography>
                  </Grid>
                  <Grid item>
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={submitChanges}
                      data-cy="review-active-students"
                    >
                      Schedule Selected Students
                      <ArrowForward />
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent>
                <DataTable
                  columns={columns}
                  data={records}
                  defaultSortField={"name"}
                  onSelectedRowsChange={updateSelectedRows}
                  paginated={false}
                  selectedRows={selectedStudents}
                  selectableRowSelected={checkRowSelected}

                  highlightOnHover
                  noHeader
                  selectableRows
                  selectableRowsHighlight
                  striped
                />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ActivateStudentEsyPage;
