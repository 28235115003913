import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardContent } from '@material-ui/core';
import { Appointment } from '../model';
import AppointmentCommentsList from './AppointmentCommentsList';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import DataTable from '../../shared/components/DataTable';

interface AppointmentsListProps {
  records: Appointment[];
  totalRecords?: number;
  selectableRows?: boolean;
  columns: any;
  urlSuffix?: string;
}

const AppointmentsList: React.FC<AppointmentsListProps> = (props) => {
  let history = useHistory();

  const { records = [], totalRecords, columns, urlSuffix = '', selectableRows = false } = props;

  const {
    handlePageChange,
    handleColumnSort,
    appointmentsLoading,
    selectedRows,
    setSelectedRows,
    clearSelectedRows,
  } = useContext(AppointmentsContext);

  const ExpandedCommentsList = ({ data }) => <AppointmentCommentsList mostRecentComment={data.most_recent_comment} />;

  return (
    <Card>
      <CardContent>
        <DataTable
          columns={columns}
          noHeader
          data={records}
          striped
          onRowClicked={(row: Appointment) =>
            history.push(`/students/${row.student_id}/appointments/${row.id}${urlSuffix}`)
          }
          highlightOnHover
          pointerOnHover
          pagination
          paginationServer
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25]}
          paginationTotalRows={totalRecords}
          onChangePage={handlePageChange}
          expandableRows
          expandableRowExpanded={(appointment: Appointment) => appointment.most_recent_comment !== null}
          expandableRowsComponent={<ExpandedCommentsList data />}
          sortServer
          onSort={handleColumnSort}
          selectableRows={selectableRows}
          selectableRowsHighlight
          selectedRows={selectedRows}
          onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
          progressPending={appointmentsLoading && records.length === 0}
          clearSelectedRows={clearSelectedRows}
        />
      </CardContent>
    </Card>
  );
};

export default AppointmentsList;
