import React, { FunctionComponent } from 'react';
import { Button, Card, CardContent, Typography, Grid, makeStyles } from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { Renewal } from '../../renewals/model';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import { ReactivateStudentContext } from '../contexts/ReactivateStudentContext';

interface ReactivateRenewalCardProps {
  renewal: Renewal;
  renewalLoading: boolean;
}

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: '24px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
}));

const ReactivateRenewalCard: FunctionComponent<ReactivateRenewalCardProps> = (props) => {
  const { renewal, renewalLoading } = props;
  const classes = useStyles();
  const { reactivateRenewal } = React.useContext(ReactivateStudentContext);

  const [destroyModalOpen, setDestroyModalOpen] = React.useState(false);

  const handleReactivateService = () => {
    reactivateRenewal(renewal?.id);
    setDestroyModalOpen(false);
  };

  const renewalDateLabel = renewal?.type === 'RR' ? 'Student RR Date' : 'Service End Date';

  return renewalLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <DeleteModal
        isOpen={destroyModalOpen}
        openStatusChanged={setDestroyModalOpen}
        onDelete={() => handleReactivateService()}
        confirmLabel="Confirm"
      >
        <Typography align="center" className={classes.modalText} variant="h5">
          Are you sure you want to reactivate this renewal?
        </Typography>
      </DeleteModal>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              Renewal Information
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={6} md={3}>
            <InformationField label="Status" value={renewal?.status?.toUpperCase()} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label="Stage" value={renewal?.stage} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label="Active" value={renewal?.active ? 'Yes' : 'No'} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label="Service Name" value={renewal?.service_name} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label="Type" value={renewal?.type} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label="Owner" value={renewal?.owner} />
          </Grid>
          <Grid item xs={6} md={3}>
            <InformationField label={renewalDateLabel} value={renewal?.renewal_date} />
          </Grid>
        </Grid>
        <br />
        <Button
          variant="contained"
          color="primary"
          onClick={() => setDestroyModalOpen(true)}
        >
          Reactivate Renewal
        </Button>
      </CardContent>
    </Card>
  );
};

export default ReactivateRenewalCard;
