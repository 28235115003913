import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import TimelineAppointmentCounter from './TimelineAppointmentCounter';
import ServiceProviderPicker from './ServiceProviderPicker';
import IncompleteNeedsRescheduledAppointmentsTable from './IncompleteNeedsRescheduledAppointmentsTable';
// import RenewalsOutOfComplianceTable from './RenewalsOutOfComplianceTable';
import UpcomingSchoolClosings from './UpcomingSchoolClosings';
import SupervisorTeamMembersTable from './SupervisorTeamMembersTable';
import ProductivityAlertsTable from './ProductivityAlertsTable';
import RecentlyUpdatedStudents from './RecentlyUpdatedStudents'
import { UserContext } from '../../auth/contexts/userContext';

interface SupervisorDashboardProps {
  isLoading: boolean;
  serviceProvider: string;
  setServiceProvider: any;
}

const SupervisorDashboard: FunctionComponent<SupervisorDashboardProps> = (props) => {
  const { isLoading, serviceProvider, setServiceProvider } = props;
  const { user } = React.useContext(UserContext);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div>
      <ServiceProviderPicker serviceProvider={serviceProvider} setServiceProvider={setServiceProvider} />
      <TimelineAppointmentCounter ownerParam="" supervisor_id={user?.id} />
      <Grid justify="space-between" container spacing={3}>
        <Grid item xs={6}>
          <SupervisorTeamMembersTable />
        </Grid>
        <Grid item xs={6} style={{ marginTop: 20 }}>
          <UpcomingSchoolClosings />
        </Grid>
        <Grid item xs={12}>
          <ProductivityAlertsTable />
        </Grid>
        <Grid item xs={12}>
          <IncompleteNeedsRescheduledAppointmentsTable />
        </Grid>
        <Grid item xs={12}>
          <RecentlyUpdatedStudents />
        </Grid>
      </Grid>
    </div>
  );
};

export default SupervisorDashboard;
